export default {
    list: {
        title: "payements",
        name: 'payments',
        class: "min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center",
        sousTable: false,
        options: {
            defaultPageSize: 5,
            actions: {
                isActive: true,
                method: true,
                link: true,
                name: "Actions",
                buttons: [
                    {
                        text: "new_payement",
                        icon: "add",
                        action:"addPay",
                        link: '/payments/new',
                        color: 'primary'
                    },
                    // {
                    //     text: "print_invoice",
                    //     icon: "print",
                    //     action:"printdInvoice",
                    //     color: 'primary',
                    //     class:''
                    // },
                ],
            }
        },
        columns: [
            // {
            //     type:"checkbox",
            //     elements: [{
            //        items: [{
            //            class: "",
            //            type: "checkbox",
            //        }, ],
            //    }, ]
            // },
            {
                name: "id",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "id",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "seller",
                class:"text-left",
                elements: [{
                    items: [{
                        class: "ouka",
                        name: "seller",
                        elements: [{
                            items: [{
                                name: "fullName",
                                class: "font-bold text-gray-700",
                                icone: "contacts"
                            },
                            // {
                            //     name: "email",
                            //     class: "font-light text-gray-700",
                            //     icone: "email"
                            // }
                        ]
                        }]

                    }, ],
                }, ]
            },
            {
                name: "country",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "country",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "payement_methode",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "method",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "nb_orders",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "count",
                        type: "text",
                    }],
                }, ]
            },
            // {
            //     name: "details",
            //     model: "payments",
            //     title: 'Orders'
            // },
            {
                name: "date_payement",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        date: true,
                        name: "date",
                        type: "text",
                    }],
                }, ]
            },
            {
                name: "total_amount",
                class: "",
                elements: [{
                    items: [{
                        class: "",
                        name: "amount",
                        type: "text",
                    }],
                }, ]
            },
        ],
        columnsDialog: {
            details: 'details',
            elements: [{
                    class: "",
                    name: "ID",
                    items: [{
                        referent: "order",
                        name: "id",
                    }, ]
                },
                {
                    class: "",
                    name: "customer",
                    items: [{
                        referent: "order",
                        field:"customer",
                        name: "fullName",
                    }, ]
                },
                {
                    class: "",
                    name: "total_price",
                    items: [{
                        referent: "order",
                        name: "totalPrice",
                    }, ]
                },
                {
                    class: "",
                    name: "confirmation_fees",
                    items: [{
                        name: "confirmation_vat",
                    }, ]
                },
                {
                    class: "",
                    name: "fulfillement_fees",
                    items: [{
                        name: "fulfillement_fees_vat",
                    }, ]
                },
                {
                    class: "",
                    name: "shipping_fees",
                    items: [{
                        name: "shipping_vat",
                    }, ]
                },
                {
                    class: "",
                    name: "return_fees",
                    items: [{
                        name: "shipping_refund_vat",
                    }, ]
                },
                {
                    class: "",
                    name: "cancellation_fees",
                    items: [{
                        name: "shipping_cancel_vat",
                    }, ]
                },
                {
                    class: "",
                    name: "total",
                    items: [{
                        name: "amount",
                    }, ]
                },
            ]
        },
        actions: {
            name: "actions",
            width: "10px",
            class: "justify-end",
            elements: [
                // {
                //     icone: "print",
                //     color: "success",
                //     key: 'print',
                //     role: true,
                //     id: "",
                //     tooltip: "Print",
                // },
                {
                    icone: "remove_red_eye",
                    color: "success",
                    key: 'read',
                    id: "",
                    role: true,
                    link: '/payments/detail',
                    tooltip: "Update",
                },
                {
                    icone: "delete",
                    color: "danger",
                    role: true,
                    key: 'delete',
                    tooltip: "Delete",
                }
            ],
        },
        filters: {
            name: 'orders',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_payement",
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [{
                        label: "method_payement",
                        key: "method",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "seller",
                        key: "seller",
                        name: "fullName",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                    {
                        label: "date",
                        key: "date",
                        value: null,
                        placeholder: "Date",
                        type: "date",
                        class: "py-2 text-xs px-2 outline-none block h-full w-full"
                    },
                ],
            },
        },
    },
}