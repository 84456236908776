<template>
  <div class="p-1">
    <div-table
      :config="configList"
      :data="payements"
      :element="element"
      @reset="reset"
      @refresh="refresh"
      @showProducts="showOrders"
      @filtrer="filtrer"
       @searchSellers="searchSellers"
      @search="search"
      :loading="loading"
      :paginate="paginate"
      @paginatation="paginatation"
      :idWharhouse="idWharhouse"
      :currentUser="currentUser"
      @Changelimit="Changelimit"
      @selectAllCheckbox="selectAllCheckbox" @getRowDetail="getRowDetail" :tabId="tabId"
    ></div-table>
  </div>
</template>

<script>
import config from "./config";
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      configList: config.list,
      loading: false,
      payements: [],
      element: {},
      sellers: [],
      tabId:[],
      show_modal: false,
      filters:{},
      limit: 100,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      methods: ["transfer", "paypal", "cash"],
     // currentUser: {},
      // idWharhouse:null,
    };
  },
  async mounted() {
    if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
    else this.$f.objectToQueryString({limit:this.limit,country:this.idWharhouse.country})

    if(this.filters.name) await this.search(this.filters.name);
    else await this.getPayement(this.filters);

    if (this.currentUser.type != "Seller") {await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
    
    this.setDataSelect("method", this.methods, null);
     if (this.currentUser.type == "Seller") {
          let pos = this.configList.columns.map(function (e) {return e.name;}).indexOf('seller');
          if(pos!=-1)  this.configList.columns.splice(pos, 1);           
     }
  },
  computed: {
    warhouseSelected(){
      return this.$store.getters["wharhouse/warhouseSelected"]({user:_.pick(this.currentUser,['countries'])})
    },
  },
   watch: {
      'warhouseSelected': async function(oldVal, newVal) {
        
      },
      'idWharhouse': async function(oldVal, newVal) {
            this.idWharhouse=oldVal;  
            await this.reset();
            if (this.currentUser.type != "Seller") {await this.getSellers();this.setDataSelect("seller", this.sellers, null);}
        }
  },
  methods: {
    selectAllCheckbox($event) {
        let columns = document.querySelectorAll('.rowCheckbox');
        this.tabId = [];
        if ($event.target.checked == true) {
            columns.forEach(column => {
                column.checked = true;             
                this.tabId.push(column.id);
            });
        } else {
            columns.forEach(column => {column.checked = false});
            this.tabId = [];
        }
    },
    getRowDetail(item, id) {
        // let rows = this.selectedRows;
        let itemInRow = this.tabId.filter(item => item === id);
        let isItemInRow = itemInRow.length > 0;
            
        if (isItemInRow) { 
            let pos = this.tabId.map(function (e) {return e;}).indexOf(id);
            this.tabId.splice(pos, 1);
        } else {
            this.tabId.push(id);
        }
          if(this.tabId.length === 0){
              let columns = document.querySelectorAll('.checkallbbox');
              columns.forEach(column => {column.checked = false});
        }
    },
     setDataSelect(model, data, splice) {
      for (var i = 0; i < this.configList.filters.elements.champs.length; i++) {
        if (
          this.configList.filters.elements.champs[i].type === "select" &&
          this.configList.filters.elements.champs[i].key === model
        ) {
          if (!splice) {
            this.configList.filters.elements.champs[i].values = data;
          } else {
            this.configList.filters.elements.champs.splice(i, 1);
          }
        }
      }
    },
    async getSellers() {
      const filter = {
        type: "Seller",
        countries:this.idWharhouse.country,
        limit:this.limit
      };
        const res = await this.$server.search("users", filter);
        if (res.content.results) this.sellers = res.content.results;
        else this.sellers = [];
    },
    searchSellers: _.debounce(async function(search,event) {
       const filter = {
        type: "Seller",
        limit:this.limit,
        countries:this.idWharhouse.country,
        name: search,
      };
      const res= await this.$server.find("users",filter);

     if (res.content.results) this.sellers = res.content.results;
     else this.sellers = [];
      this.setDataSelect("seller", this.sellers, null);
    }, 500),
    async reset(data) {
      let lmitt=this.limit;
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      if(this.filters['limit']) lmitt=this.filters['limit'];
      this.filters['page']=1;
      this.$f.objectToQueryString({limit:lmitt,country:this.idWharhouse.country})
      await this.getPayement({limit:lmitt});
    },
    async filtrer(data) {
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      delete  this.filters.page;
      delete  this.filters.name;
      this.paginate.currentpage=1;
      if(!this.filters['limit']) this.filters['limit']=this.limit;
      if(data.method) this.filters.method=data.method;else delete this.filters.method;
      if(data.date) this.filters.date=data.date;else delete this.filters.date;
      if(data.seller) this.filters.seller=data.seller._id;else delete this.filters.seller;
      this.$f.objectToQueryString(this.filters)
      await this.getPayement(this.filters);
    },
    /**********************************User and Status******************************************************/
    async getUser() {
      const res = await this.$me;
      this.currentUser = res.content;
    },
    async refresh() {
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.$f.objectToQueryString(this.filters)
      if(this.filters.name) await this.search(this.filters.name);
      else await this.getPayement(this.filters);
      if(this.filters['page'] && this.filters['page']>1 && this.payements.length==0){
          this.filters['page']= --this.filters['page'];
          this.$f.objectToQueryString(this.filters)
          await this.getPayement(this.filters);
      }
    },

    showOrders(index) {
      if (this.element !== this.payements[index]) {
        this.element = this.payements[index];
        //console.log("Je suis this.element", this.element);
      } else this.element = null;
    },
    sumObjAmountCurrency(objArr,refunds=[],expeditions=[],charges=[]) {
      if(refunds.length>0) objArr = objArr.concat(refunds);
      if(expeditions.length>0) objArr = objArr.concat(expeditions);
      if(charges.length>0) objArr = objArr.concat(charges);

      let result = objArr.reduce((acc, curr) => {
        let item = acc.find(item => item.currency === curr.currency);

        if (item) {
          if(!item.amount) item.amount=0; if(!curr.amount) curr.amount=0;
          if(!item.count) item.count=0; if(!curr.count) curr.count=0;
          if(!item.fees) item.fees=0; if(!curr.fees) curr.fees=0;
          if(!item.totalPrice) item.totalPrice=0; if(!curr.totalPrice) curr.totalPrice=0;
          if(!item.fees_amount) item.fees_amount=0; if(!curr.fees_amount) curr.fees_amount=0;

          item.amount += curr.amount;
          item.count += curr.count;
          item.fees += curr.fees;
          item.totalPrice += curr.totalPrice;
          item.amount -= curr.fees_amount;

        } else {
           if(!curr.amount) curr.amount=0;
           if(!curr.count) curr.count=0;
           if(!curr.fees) curr.fees=0;
           if(!curr.totalPrice) curr.totalPrice=0;
           if(!curr.fees_amount) curr.fees_amount=0;
          acc.push(curr);
        }

        return acc;
      }, []);

      return result;
    },
    async getPayement(filters) {
      this.loading = true;
      if (this.currentUser.type === "Seller") filters.seller = this.currentUser._id;
      filters.country=this.idWharhouse.country;
      delete filters.country;
      console.log('filter________________________',filters)
      const res = await this.$server.search("payments", filters);
      this.loading = false;
      if (res.content.results) {
        this.payements = res.content.results;
         this.payements.forEach(arg => {
          arg.count=arg.count+' Orders';
          if(arg.amountCurrency && arg.amountCurrency.length>0){
              arg.amountCurrency=this.sumObjAmountCurrency(arg.amountCurrency,arg.amountRefundsCurrency||[],arg.amountExpeditionsCurrency||[],arg.amountChargesCurrency||[]);
              arg.amount="";
              arg.amountCurrency.forEach((price,j) => {
                arg.amount+=`${price.amount.toFixed(2)} <sup>${price.currency}</sup>`;
              if(j!=arg.amountCurrency.length-1)  arg.amount+=` | `;
          });
          }else arg.amount=arg.amount.toFixed(2)+' <sup>'+this.idWharhouse.currency+'</sup>';
          
        });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.payements = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
    },
    async search(data) {
      this.paginate= {total: 0,currentpage: 1,lastpage: 1,per_page: 0,};
      let limitt=this.limit
      if(this.filters['limit']) limitt=this.filters['limit'];

      let search = {name:data.trim(),limit:parseInt(limitt)}
      if(this.currentUser.type == "Seller")  search.seller = this.currentUser._id;
       search.country=this.idWharhouse.country;
       this.$f.objectToQueryString(search)
       const  res = await this.$server.find("payments", search);
       if (res.content.results) {
         this.payements = res.content.results; 
          this.payements.forEach(arg => {
          arg.count=arg.count+' Orders';
          if(arg.amountCurrency && arg.amountCurrency.length>0){
            arg.amountCurrency=this.sumObjAmountCurrency(arg.amountCurrency,arg.amountRefundsCurrency||[],arg.amountExpeditionsCurrency||[],arg.amountChargesCurrency||[]);
              arg.amount="";
              arg.amountCurrency.forEach((price,j) => {
                arg.amount+=`${price.amount.toFixed(2)} <sup>${price.currency}</sup>`;
              if(j!=arg.amountCurrency.length-1)  arg.amount+=` | `;
          });
          }else arg.amount=arg.amount.toFixed(2)+' <sup>'+this.idWharhouse.currency+'</sup>';
        });
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.payements = [];this.paginate={total: 0,currentpage: 1,lastpage: 1,per_page: 0,}
      }
        
    },
    async remove(id) {
      this.$confirm(this.$t('confirm_delete_payment')).then(async (res) => {
        if (res) {
          const resp = await this.$server.delete("payments", { id: id });
          await this.refresh();
          alert(this.$t('delete_payment'), "success");
        }
      });
    },
    /********************************************************************************************/
    async paginatation(direction) {
      var page=0;
      if(direction == "next"){
          page=++this.paginate.currentpage;
      }else{
          page=--this.paginate.currentpage;
      }
      if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
      this.filters['page']=page;
      this.$f.objectToQueryString(this.filters);
      this.getPayement(this.filters);
    },
    async Changelimit(val){
       if(this.$f.QueryStringToobject()) this.filters=this.$f.QueryStringToobject();
       this.filters['limit']=val;
       this.filters['page']=1;
       this.paginate.currentpage=1;
       this.$f.objectToQueryString(this.filters)
       if(this.filters.name) await this.search(this.filters.name);
       else this.getPayement(this.filters);
    },
  },
};
</script>

<style>
/* animation for vue transition tag */
dialog[open] {
  animation: appear 0.15s cubic-bezier(0, 1.8, 1, 1.8);
}
dialog::backdrop {
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(54, 54, 54, 0.5));
  backdrop-filter: blur(3px);
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/**********************************Table Product PopUp ***************************/

@media (min-width: 640px) {

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
</style>